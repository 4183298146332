#container-principal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
   
}

.button-container {
    max-width: 100%;
    width: 600px;
    margin-top: 15px;
    margin-bottom: 15px;
}

#botonDescargar {
    background-color:  #24a5ba;
}

#container-instrucciones {
    max-width: 100%;
    width: 600px;
    margin: 0 auto 100px auto;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

#container-instrucciones h2#titulo-instrucciones{
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 0 ;
    background-color: #f0f0f0;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.instructions-content {
    margin: 5px auto;
}



.container p {
    text-align: justify;
    color: #1976D2;
}

.container p strong#aling-izq {
    display: inline-block;
    text-align: left;
    
}


.instructions-content p {
    margin-bottom: 0;
    padding: 0px;
   
}

.instruction-icon {
    width: 25px; /* Ajusta el tamaño de las imágenes según sea necesario */
    height: 25px;
    margin-right: 5px; /* Espaciado entre el ícono y el texto */
    border-radius: 1px; /* Borde cuadrado para las imágenes */
    background-color: #f0f0f0;
}

.instruction-icon2 {
    width: 35px; /* Ajusta el tamaño de las imágenes según sea necesario */
    height: 35px;
    margin-right: 5px; /* Espaciado entre el ícono y el texto */
    border-radius: 1px; /* Borde cuadrado para las imágenes */
    background-color: #f0f0f0;
}

.instruction-icon3 {
    width: 30px; /* Ajusta el tamaño de las imágenes según sea necesario */
    height: 30px;
    margin-right: 5px; /* Espaciado entre el ícono y el texto */
    border-radius: 1px; /* Borde cuadrado para las imágenes */
    background-color: #f0f0f0;
}

.instruction-icon1 {
    display: block; /* Cambiar a bloque para alinear verticalmente */
    margin: 0 auto 5px auto; /* Centrar horizontalmente */
    width: 12%; /* Ajusta el tamaño de las imágenes según sea necesario */
    border-radius: 5px; /* Borde cuadrado para las imágenes */
    background-color: #f0f0f0;
  }

  #manito {
    display: block; 
    margin: 0 auto 5px auto;
    text-align: center;
  }

  #titulo-pasos {
    display: block; 
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 3px auto  auto;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 10px; /* Borde redondeado para el contenedor */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .instructions-content p.important {
    
    padding-top: 0;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f0f0f0;
    border-radius: 20px; /* Borde redondeado para el contenedor */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px ;
  }

strong#azul{

    color: #1976D2;
} 

strong {
    color: #24a5ba;
}


/* Media query para pantallas pequeñas */
@media screen and (max-width: 700px) {
    #container-principal {
        margin-top: 70px;
    }

    .button-container {
        margin: 5px auto 0px auto; 
    }

    #container-instrucciones {
        width: 85%;
        margin: 0px 5px 0px 5px;
        align-items: center; /* Centrar verticalmente los elementos */
    }
}

 /* Media query para pantallas grandes */
@media screen and (min-width: 701px) {
    
    #container-principal {
        margin-top: 105px;
    }
  }   