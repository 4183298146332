
body {
  box-sizing: border-box,;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color:#dcdcdc;
  
  
}

.button {
  display: block;
  margin: 20px auto; /* Ajusta el margen en lugar de usar auto en vertical */
  padding: 15px; /* Aumenta el relleno para hacer el botón más grande */
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  background-color: #1976D2;
  color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra sutil */
  transition: background-color 0.3s, box-shadow 0.3s; /* Agrega transición al color de fondo y a la sombra */
  font-family: Arial, sans-serif;
  font-size: 16px; /* Aumenta el tamaño de la fuente del botón */
  font-weight: bold;
}

@media only screen and (max-width: 699px) {
  .button {
    width: 95%; /* Para pantallas pequeñas */
  }
}

@media only screen and (min-width: 700px) {
  .button {
    width: 60%; /* Para pantallas grandes */
  }
}

.button:hover {
  background-color: #1565c0; /* Cambia el color de fondo al pasar el ratón */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Aumenta la sombra al pasar el ratón */
}

h2 {
  
  color: #1976D2;
}