.titulo-menuQr {
  
  font-size: 1rem;
  text-align: center;
  color: #1976D2;
  margin-top: 30px;
  margin-bottom: 5px;
}

/*son tres div para la regla de los tercios y quede mejor centrado todo no use el body porque sino el navdar y el pie de pagina se cortaban*/
#container-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-top: 100px;
}

.menuQr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.menuQr > div {
  flex: 1 1 calc(50% - 40px);
  max-width: calc(50% - 40px);
  margin: 20px; /* Ajusta el margen entre las tarjetas */
}

.tarjeta {
  width: calc(50% - 4vw); /* Ajustar el ancho de las tarjetas */
  margin: 2vw; /* Ajustar el margen entre las tarjetas */
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
 
}

img {
  width: 85%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  background-color: #f0f0f0;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  margin-left: auto; /* Hace que el margen izquierdo sea automático */
  margin-right: auto; /* Hace que el margen derecho sea automático */

}

.text-container {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}

h2, h3 {
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

h3 {
  margin-top: 5px;
  color: #24a5ba;
}

#sombra {
   
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 5px 0 5px;
  text-align: center;
  background-color:  #f0f0f0;
  border-radius: 10px; /* Borde redondeado para el contenedor */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h4#verde{
  color: #24a5ba;
 
}

#botonInstalarPWA {
  background-color: #24a5ba !important; /* ¡Asegúrate de que no haya otros estilos más específicos anulando este! */
}



@media only screen and (max-width: 700px) {

  .titulo-menuQr{
    margin-top: 2px ;
    margin-bottom: 2px;
    margin-left: 3px;
    margin-right: 3px;
   
  }
 

  .menuQr {
    flex-direction: column;
    align-items: center;
  }

  .menuQr > div {
    width: 90%; /* Ajustar el ancho de las tarjetas */
    max-width: 100%;
  }

  .tarjeta {
    width: 90%; /* Ajustar el ancho máximo de las tarjetas */
    margin: 5vw 0; /* Ajustar el margen entre las tarjetas */
  }

  img{
    width: 80%;
    margin: 10px auto 0; /* Ajustar el margen vertical y hacer que los márgenes izquierdo y derecho sean iguales */
  }

 

}
