/* Estilos base */
/* Estilos base */
.navbar {
    background-color: #1565c0; /* Azul verdoso */
    padding: 5px 8px; /* Reducimos aún más el padding interior del navbar */
    display: flex;
    width: 100%;
    align-items: center; /* Centra verticalmente el contenido */
    color: white; /* Color del texto */
    position: fixed; /* Hace que la barra de navegación sea fija */
    top:  0 ; /* Ancla la barra de navegación en la parte superior */
    left: 0; /* Asegura que la barra de navegación esté alineada a la izquierda */
    background-image: linear-gradient(45deg, transparent 50%, rgba(255,255,255,0.1) 50%), linear-gradient(-45deg, transparent 50%, rgba(255,255,255,0.1) 50%);
    background-size: 20px 20px; /* Tamaño del patrón */
    z-index: 1000; 
    
}



.logo-container {
    display: flex; /* Utilizamos flexbox para organizar el logo y el texto */
    align-items: center; /* Centra verticalmente los elementos dentro del contenedor */
}

.logo {
    width: 50px; /* Reducimos el ancho del logo */
    height: 50px; /* Fijamos la altura del logo */
    object-fit: contain; /* Ajustamos la imagen dentro del contenedor */
    border-radius: 10px; /* Opcional: aplicamos un borde redondeado al logo */
    margin: 5px auto;
    background-color: #fff;
}

.texto {
    margin-left: 8px; /* Reducimos el espacio entre el logo y el texto */
    font-weight: 200; /* Hace que el texto sea más fino */
    font-size: 12px; /* Reducimos aún más el tamaño del texto */
    
}



/* Media query para pantallas más grandes */
@media only screen and (min-width: 700px) {
    .navbar {
        padding: 10px 0px 10px 20px; /* Ajustamos el padding para pantallas más grandes */
        width: 100%; /* Hacemos que el navbar ocupe todo el ancho */
        margin: 0; /* Elimina cualquier margen predeterminado en el navbar */
    }


    .logo {
        width: 75px; /* Aumentamos el tamaño del logo */
        height: 75px; /* Ajustamos la altura del logo */
        
    }

    .texto {
        font-size: 14px; /* Aumentamos el tamaño del texto para pantallas más grandes */
    }
}


